<template>
  <div class="personnalises-setting">
    <div class="header">
      <div class="titre">
        <h4>Gestion des champs personnalisés</h4>
      </div>
      <div class="button">
        <b-dropdown
          text="Ajouter un champ personnalisé"
          size="sm"
          variant="success"
        >
          <b-dropdown-item
            v-for="item in getCustomFieldsMenu"
            :key="item.id"
            v-b-modal.ModaladdChamps
            :disabled="getCustomFieldsLoading"
            @click="Drapdownbutton(item)"
          >
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <hr />
    <div v-if="initLoading" class="init-loading three-dots-loading mt-2">
      Chargement en cours
    </div>
    <div v-else>
      <div class="menuBlock">
        <b-nav class="slidemenu">
          <input
            type="radio"
            name="slideItem"
            id="slide-item-1"
            class="slide-toggle"
            @click="Menu('contacts')"
            checked
          />
          <label for="slide-item-1"> <span>Contacts</span></label>
          <input
            type="radio"
            name="slideItem"
            id="slide-item-2"
            class="slide-toggle"
            @click="Menu('projets')"
          />
          <label for="slide-item-2"> <span>Projets</span></label>
          <div class="clear"></div>
          <div class="slider">
            <div class="bar"></div>
          </div>
        </b-nav>
      </div>

      <div
        class="content-table-custem-fields"
        v-if="selectedlink === 'contacts' || selectedlink === 'projets'"
      >
        <div class="mb-4 search">
          <span class="searchIcon" title="search">
            <font-awesome-icon icon="search" />
          </span>
          <input
            class="input-search"
            type="text"
            v-model="searchFilter"
            placeholder="Rechercher un champ "
          />

          <div
            v-if="getCustomFieldsLoading"
            class="loadingMenu three-dots-loading mt-2"
          >
            Chargement en cours
          </div>
        </div>

        <div class="responsive-table">
          <b-table
            small
            show-empty
            id="table"
            :items="getcustomFieldsComputed"
            :fields="computedFields"
            :current-page="page"
            :per-page="0"
            sort-icon-right
            no-sort-reset
            :sort-by.sync="sort_by"
            :sort-desc.sync="sortDesc"
            no-local-sorting
            @sort-changed="sort"
            empty-text="Il n'y a aucun enregistrement à afficher"
            empty-filtered-text="Aucun enregistrement ne correspond à votre demande"
          >
            <template v-slot:cell(name)="data">
              <p class="font-p">{{ data.item.name }}</p>
            </template>
            <template v-slot:cell(default)="data">
              <p class="font-p chacked">
                <font-awesome-icon
                  icon="check"
                  v-if="data.item.default === 1"
                />
              </p>
            </template>

            <template v-slot:cell(subtype)="data" v-if="computedsubTypeExiste">
              <div v-if="data.item.subtypes && data.item.subtypes.data">
                <p
                  class="font-p"
                  v-for="(subType, index) in data.item.subtypes.data"
                  :key="subType.id"
                >
                  <template
                    v-if="
                      index < data.item.subcategories.data.length - 1 &&
                        data.item.subcategories.data.length > 1
                    "
                    >,</template
                  >
                  {{ subType.name }}
                </p>
              </div>
            </template>
            <template v-slot:cell(subcategorie)="data">
              <div
                v-if="data.item.subcategories && data.item.subcategories.data"
              >
                <p
                  class="font-p"
                  v-for="(subCategorie, index) in data.item.subcategories.data"
                  :key="subCategorie.id"
                >
                  {{ subCategorie.name }}
                  <template
                    v-if="
                      index < data.item.subcategories.data.length - 1 &&
                        data.item.subcategories.data.length > 1
                    "
                    >,</template
                  >
                </p>
              </div>
            </template>
            <template v-slot:cell(key)="data">
              <div class="content-key">
                <p class="font-p ellipsis">
                  {{ data.item.key }}
                </p>
                <div
                  @click="copyToClipboard(data.item)"
                  class="copier ml-3"
                  title="Copier la clé"
                >
                  <font-awesome-icon class="icon_copy" icon="copy" />
                </div>
              </div>
            </template>
            <template v-slot:cell(actions)="data">
              <b-dropdown
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="..."
              >
                <b-dropdown-item @click="handleUpdateClick(data.item)"
                  >Modifier</b-dropdown-item
                >
                <b-dropdown-item @click="handleDeleteClick(data.item)"
                  >Supprimer</b-dropdown-item
                >
                <b-dropdown-item @click="copyToClipboard(data.item)"
                  ><font-awesome-icon class="img_copy" icon="copy" /><span
                    class="copy"
                    >Copier la clé API</span
                  ></b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <!-- ADD_CUSTOM_FIELDS -->
    <b-modal
      no-close-on-backdrop
      id="ModaladdChamps"
      ref="ModaladdChamps"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
      :title="
        champsToAdd && selectedlinkButton.name === 'projets'
          ? 'Ajouter le champ de projet'
          : 'Ajouter le champ de contact'
      "
    >
      <form @submit.prevent="handleSubmitAddCustomFields()">
        <b-row>
          <b-col>
            <b-form-group
              label="Nom du champ (obligatoire)"
              label-for="nom-input"
            >
              <b-form-input
                id="Nom-input"
                v-model="champsToAdd.name"
                required
              ></b-form-input>
            </b-form-group>

            <b-card class="a-card mb-3">
              <div
                class="a-card-header"
                @click="subcategorie == true ? (view = !view) : null"
              >
                <div
                  class="a-card-header-flex"
                  :class="{
                    'a-card-header-subcategorie': subcategorie == true
                  }"
                >
                  <div class="flex align-center">
                    <div class="title-card">
                      Pixel
                    </div>
                  </div>

                  <div v-if="subcategorie == true">
                    <font-awesome-icon
                      icon="angle-down"
                      class="flesh-down"
                      v-if="view == false"
                    />
                    <font-awesome-icon
                      icon="angle-up"
                      class="flesh-up"
                      v-if="view == true"
                    />
                  </div>
                </div>
                <hr
                  class="line-hr"
                  :class="{
                    'display-body': subcategorie == true && view == false
                  }"
                />
              </div>
              <div
                class="a-card-body"
                :class="{
                  'display-body': subcategorie == true && view == false
                }"
              >
                <div v-if="getListeTypePixel && getListeTypePixel.length">
                  <div v-for="(item, index) in getListeTypePixel" :key="index">
                    <b-form-group
                      :label="' Champs Pixel -' + ' ' + item.name"
                      label-for="nom-input-update"
                    >
                      <b-form-input
                        id="Nom-input"
                        v-model="champsToAdd.typePixel[index]"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div v-else class="bleu-aucun">Aucun Type des Pixels</div>
              </div>
            </b-card>
            <b-form-group
              label="Type du champ (obligatoire)"
              label-for="Type-input"
            >
              <b-form-select id="Type-input" v-model="champsToAdd.field_type">
                <option
                  v-for="item in getAllTypescustomFields"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.label }}</option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Sous-type"
              v-if="selectedlinkButton.name === 'projets'"
            >
              <multiselect
                class="multiselectChamp mb-2"
                v-model="champsToAdd.sub_type"
                :options="getAllSubTypes || []"
                placeholder="Rechercher"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                :showLabels="false"
                label="name"
                track-by="name"
                :multiple="true"
              >
                <span slot="noResult">Aucune option trouvée.</span>
                <span slot="noOptions">Aucune option trouvée.</span>
              </multiselect>
            </b-form-group>
            <b-form-group label="Catégorie du champ " label-for="section-input">
              <multiselect
                class="multiselectChamp mb-2"
                @input="changeCategorieInAddModal($event)"
                v-model="champsToAdd.section"
                :options="getListCategorieInTable || []"
                placeholder="Rechercher"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                :showLabels="false"
                label="name"
                track-by="name"
                :multiple="true"
              >
                <span slot="noResult">Aucune option trouvée.</span>
                <span slot="noOptions">Aucune option trouvée.</span>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Sous catégories"
              label-for="section-input"
              v-if="champsToAdd.section.length > 0"
            >
              <div
                v-if="
                  getCustemFieldsSubCategorieList &&
                    getCustemFieldsSubCategorieList.length
                "
              >
                <div
                  v-for="(subCat, index) in getCustemFieldsSubCategorieList"
                  :key="subCat.id"
                >
                  <b-row>
                    <b-col
                      ><b-form-checkbox
                        v-model="champsToAdd.sub_categories[index]"
                        name="checkbox-1"
                        :value="subCat.id"
                        :unchecked-value="null"
                      >
                        {{ subCat.name }}
                      </b-form-checkbox></b-col
                    >
                    <b-col
                      ><b-form-checkbox
                        v-model="champsToAdd.sub_categories_entet[index]"
                        switch
                        size="sm"
                        :value="1"
                        :unchecked-value="0"
                        >Champ entête
                      </b-form-checkbox></b-col
                    >
                  </b-row>
                </div>
              </div>
              <div
                v-if="
                  !getCustomFieldsLoading &&
                    champsToAdd.section.length > 0 &&
                    (!getCustemFieldsSubCategorieList ||
                      !getCustemFieldsSubCategorieList.length)
                "
                class="aucun-sous-category"
              >
                Aucun Sous catégories
              </div>
            </b-form-group>
            <b-form-group
              v-if="champsToAdd.field_type == 4 || champsToAdd.field_type == 5"
              label="Options (obligatoire)"
              label-for="option-input"
            >
              <b-input-group class="mb-3" prepend="Options">
                <b-form-input v-model="champsToAdd.nameOption"></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    variant="success"
                    :disabled="getCustomFieldsLoading"
                    @click="addOptionsAdd(champsToAdd)"
                  >
                    <font-awesome-icon class="" icon="plus" />
                  </b-button> </b-input-group-append
              ></b-input-group>
              <Container
                group-name="col"
                @drop="onCardDropAddCustomFieldOption($event)"
                drag-class="card-ghost"
                drop-class="card-ghost-drop"
                :drop-placeholder="dropPlaceholderOptions"
                :lock-axis="'y'"
                style="cursor: move;"
                :class="'hello-002'"
              >
                <Draggable
                  v-for="item in champsToAdd.selectedOption"
                  :key="item.id"
                >
                  <b-input-group>
                    <b-form-input v-model="item.name"></b-form-input>
                    <b-input-group-append>
                      <b-button
                        size="sm"
                        @click="addOptionsDelete(item.id)"
                        :disabled="getCustomFieldsLoading"
                      >
                        <font-awesome-icon class icon="trash-alt" />
                      </b-button> </b-input-group-append
                  ></b-input-group>
                </Draggable>
              </Container>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="message">
          <div v-if="getCustomFieldsLoading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="getCustomFieldsError" class="error">
            <ul v-if="Array.isArray(getCustomFieldsError)">
              <li v-for="(e, index) in getCustomFieldsError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getCustomFieldsError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            :disabled="getCustomFieldsLoading"
            @click="hideModal('ModaladdChamps')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button
            variant="success"
            type="submit"
            :disabled="getCustomFieldsLoading"
          >
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- UPDATE_CUSTOM_FIELDS -->
    <b-modal
      no-close-on-backdrop
      id="Modalupdatechamps"
      ref="Modalupdatechamps"
      :hide-footer="true"
      @hidden="resetModal()"
      :title="
        champsToUpdate && champsToUpdate.menu === 'projets'
          ? 'Modifier le champ de projet'
          : 'Modifier le champ de contact'
      "
    >
      <form
        v-if="champsToUpdate"
        @submit.prevent="handleSubmitUpdateCustomFields"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Nom du champ (obligatoire)"
              label-for="nom-input"
            >
              <b-form-input
                id="Nom-input"
                v-model="champsToUpdate.name"
                required
              ></b-form-input>
            </b-form-group>
            <b-card class="a-card mb-3">
              <div
                class="a-card-header"
                @click="subcategorie == true ? (view = !view) : null"
              >
                <div
                  class="a-card-header-flex"
                  :class="{
                    'a-card-header-subcategorie': subcategorie == true
                  }"
                >
                  <div class="flex align-center">
                    <div class="title-card">
                      Pixel
                    </div>
                  </div>

                  <div v-if="subcategorie == true">
                    <font-awesome-icon
                      icon="angle-down"
                      class="flesh-down"
                      v-if="view == false"
                    />
                    <font-awesome-icon
                      icon="angle-up"
                      class="flesh-up"
                      v-if="view == true"
                    />
                  </div>
                </div>
                <hr
                  class="line-hr"
                  :class="{
                    'display-body': subcategorie == true && view == false
                  }"
                />
              </div>
              <div
                class="a-card-body"
                :class="{
                  'display-body': subcategorie == true && view == false
                }"
              >
                <div v-if="getListeTypePixel && getListeTypePixel.length">
                  <div v-for="(item, index) in getListeTypePixel" :key="index">
                    <b-form-group
                      :label="' Champs Pixel -' + ' ' + item.name"
                      label-for="nom-input-update"
                    >
                      <b-form-input
                        id="Nom-input"
                        v-model="listePixelTypeUpdate[index]"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div v-else class="bleu-aucun">Aucun Type des Pixels</div>
              </div>
            </b-card>
            <b-form-group
              label="Type du champ (obligatoire)"
              label-for="Type-input"
            >
              <b-form-select
                id="Type-input"
                v-model="champsToUpdate.field_type"
                disabled
              >
                <option
                  v-for="item in getAllTypescustomFields"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.label }}</option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Sous-type"
              v-if="
                champsToUpdate.subtypes &&
                  champsToUpdate.subtypes.data &&
                  selectedlink === 'projets'
              "
            >
              <multiselect
                class="multiselectChamp mb-2"
                v-model="champsToUpdate.subtypes.data"
                :options="getAllSubTypes || []"
                placeholder="Rechercher"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                :showLabels="false"
                label="name"
                track-by="name"
                :multiple="true"
              >
                <span slot="noResult">Aucune option trouvée.</span>
                <span slot="noOptions">Aucune option trouvée.</span>
              </multiselect>
            </b-form-group>
            <b-form-group label="Catégorie du champ " label-for="section-input">
            </b-form-group>

            <multiselect
              @input="changeCategorieInUpdateModal($event)"
              class="multiselectChamp mb-2"
              v-model="champsToUpdate.categorie"
              :options="sectionsUpdate || []"
              placeholder="Rechercher"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              :showLabels="false"
              label="name"
              track-by="name"
              :multiple="true"
            >
              <span slot="noResult">Aucune option trouvée.</span>
              <span slot="noOptions">Aucune option trouvée.</span>
            </multiselect>
            <b-form-group
              label="Sous catégories"
              label-for="section-input"
              v-if="champsToUpdate.categorie.length"
            >
              <div
                v-if="
                  champsToUpdate.sub_categories &&
                    champsToUpdate.sub_categories.length
                "
              >
                <div
                  v-for="(subCat, index) in champsToUpdate.sub_categories"
                  :key="subCat.id"
                >
                  <b-row v-if="champsToUpdate.sub_categories">
                    <b-col>
                      <b-form-checkbox
                        v-model="
                          champsToUpdate.sub_categories[index].subcategory_id
                        "
                        name="checkbox-1"
                        :value="subCat.id"
                        :unchecked-value="null"
                      >
                        {{ subCat.name }}
                      </b-form-checkbox></b-col
                    >
                    <b-col>
                      <b-form-checkbox
                        v-model="
                          champsToUpdate.sub_categories[index]
                            .is_subcategory_header
                        "
                        switch
                        size="sm"
                        :value="1"
                        :unchecked-value="0"
                        >Champ entête
                      </b-form-checkbox></b-col
                    >
                  </b-row>
                </div>
              </div>
              <div
                v-if="
                  !getCustomFieldsLoading &&
                    (!champsToUpdate.sub_categories ||
                      !champsToUpdate.sub_categories.length)
                "
                class="aucun-sous-category"
              >
                Aucun Sous catégories
              </div>
            </b-form-group>
            <b-form-group
              v-if="
                champsToUpdate.default === 0 &&
                  (champsToUpdate.field_type == 4 ||
                    champsToUpdate.field_type == 5)
              "
              label="Options (obligatoire)"
              label-for="option-input"
            >
              <b-input-group class="mb-3" prepend="Options">
                <b-form-input
                  v-model="champsToUpdate.nameOption"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    variant="success"
                    :disabled="getCustomFieldsLoading"
                    @click="updateOptionsAdd(champsToUpdate)"
                  >
                    <font-awesome-icon class="" icon="plus" />
                  </b-button> </b-input-group-append
              ></b-input-group>
              <Container
                group-name="col"
                drag-class="card-ghost"
                drop-class="card-ghost-drop"
                :drop-placeholder="dropPlaceholderOptions"
                :lock-axis="'y'"
                style="cursor: move;"
                :class="'hello-002'"
              >
                <Draggable v-for="item in computedOptions" :key="item.id">
                  <Container
                    group-name="col"
                    @drop="
                      onCardDropUpdateCustomFieldOptions(
                        $event,
                        item.options.data,
                        item.id
                      )
                    "
                    drag-class="card-ghost"
                    drop-class="card-ghost-drop"
                    :drop-placeholder="dropPlaceholderOptions"
                    :lock-axis="'y'"
                    style="cursor: move;"
                    :class="'hello-002'"
                  >
                    <Draggable
                      v-for="option in item.options.data"
                      :key="option.id"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="option.label"
                          :disabled="getCustomFieldsLoading"
                          @change="updateOptionsName(option)"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            size="sm"
                            title="Supprimer une option"
                            :disabled="
                              getCustomFieldsLoading ||
                                (item.options.data &&
                                  item.options.data.length <= 2)
                            "
                            @click="updateOptionsDelete(option.id)"
                          >
                            <font-awesome-icon class icon="trash-alt" />
                          </b-button> </b-input-group-append
                      ></b-input-group>
                    </Draggable>
                  </Container>
                </Draggable>
              </Container>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="message">
          <div v-if="getCustomFieldsLoading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="getCustomFieldsError" class="error">
            <ul v-if="Array.isArray(getCustomFieldsError)">
              <li v-for="(e, index) in getCustomFieldsError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getCustomFieldsError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            :disabled="getCustomFieldsLoading"
            @click="hideModal('Modalupdatechamps')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button
            variant="success"
            type="submit"
            :disabled="getCustomFieldsLoading"
          >
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- DELETE_CUSTOM_FIELDS -->
    <b-modal
      no-close-on-backdrop
      ref="ModalDelete"
      id="ModalDelete"
      :title="
        champsToDelete && champsToDelete.menu === 'projets'
          ? 'Supprimer le champ de projet'
          : 'Supprimer le champ de contact'
      "
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de vouloir supprimer le champ personnalisé
        <strong> {{ champsToDelete ? champsToDelete.name : '' }}</strong>
        ?
      </p>
      <div class="message">
        <div v-if="getCustomFieldsLoading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <div v-if="getCustomFieldsError" class="error">
          <ul v-if="Array.isArray(getCustomFieldsError)">
            <li v-for="(e, index) in getCustomFieldsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getCustomFieldsError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('ModalDelete')"
          variant="outline-secondary"
          :disabled="getCustomFieldsLoading"
        >
          Annuler
        </b-button>
        <b-button
          variant="success"
          @click="handleSubmitDeleteCustomFields()"
          :disabled="getCustomFieldsLoading"
        >
          Enregistrer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { Container, Draggable } from 'vue-smooth-dnd'
export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      searchFilter: '',
      champsToAdd: {
        name: null,
        field_type: null,
        options: [],
        selectedOption: [],
        nameOption: null,
        order: null,
        section: [],
        sub_type: [],
        sub_categories: [],
        sub_categories_entet: [],
        typePixel: []
      },
      listePixelTypeUpdate: [],
      view: false,
      subcategorie: true,
      id_menu: null,
      customizable: 1,
      initLoading: true,
      champsToDelete: null,
      champsToUpdate: null,
      selectedlink: 'contacts',
      selectedlinkButton: '',
      enabled: true,
      dragging: false,
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      sectionsList: [
        {
          text: 'ADRESSE DES TRAVAUX',
          value: 'adresse_travaux',
          section: 'projets'
        },
        {
          text: 'SURFACES',
          value: 'surfaces',
          section: 'projets'
        },
        {
          text: 'DATES TRAITEMENT PROJET',
          value: 'dates_traitement_projet',
          section: 'projets'
        },
        {
          text: 'INFORMATION AIDES',
          value: 'information_aides',
          section: 'projets'
        },
        {
          text: 'INTERVENANTS PROJET',
          value: 'intervenants_projet',
          section: 'projets'
        },
        {
          text: 'INFORMATIONS MARKETING',
          value: 'informations_marketing',
          section: 'projets'
        },
        {
          text: 'INFORMATIONS TECHNIQUES',
          value: 'informations_techniques',
          section: 'projets'
        },
        {
          text: 'INFORMATIONS CPF',
          value: 'information_cpf',
          section: 'projets'
        },
        {
          text: 'INFORMATIONS CLIENT',
          value: 'information_client',
          section: 'contacts'
        },
        {
          text: 'INFORMATIONS LOGEMENT',
          value: 'information_logement',
          section: 'contacts'
        },
        {
          text: 'INFORMATIONS FISCALES',
          value: 'information_fiscals',
          section: 'contacts'
        },
        {
          text: 'INFORMATIONS AIDES',
          value: 'information_aides',
          section: 'contacts'
        }
      ],
      fields: [
        { key: 'name', label: 'Nom', sortable: true },
        {
          key: 'subcategorie',
          label: 'Sous-catégories'
        },
        { key: 'key', label: 'Clé' },
        { key: 'default', label: 'Champ systéme', tdClass: 'default-td-class' },
        { key: 'actions', label: '' }
      ],
      fieldsProject: [
        { key: 'name', label: 'Nom', sortable: true },
        {
          key: 'subtype',
          label: 'Sous-types',
          tdClass: 'subtype-td-class',
          thClass: 'subtype-td-class'
        },
        {
          key: 'subcategorie',
          label: 'Sous-catégories'
        },
        { key: 'key', label: 'Clé' },
        { key: 'default', label: 'Champ systéme', tdClass: 'default-td-class' },
        { key: 'actions', label: '' }
      ],
      page: 1,
      sort_by: 'name',
      sort_by_desc: 'name',
      sortDesc: false
    }
  },
  methods: {
    ...mapActions([
      'addCustomFields',
      'updateCustomFields',
      'deleteCustomFields',
      'ReordonnerCustomFields',
      'fetchAllTablesCustomFields',
      'fetchAllCustomFieldsTypes',
      'fetchAllCustomFields',
      'applyDragCustomFields',
      'addCustomFieldsOptions',
      'deleteCustomFieldsOptions',
      'updateCustomFieldsOptions',
      'applyDragCustomFieldsOptions',
      'resetErrorCustomField',
      'fetchListCategorieInTable',
      'fetchOneCustomFields',
      'fetchSubCategorieForCustemFields',
      'fetchAllSubTypesProject',
      'fetchListTypePixel'
    ]),
    sort() {
      this.sortDesc = !this.sortDesc
      if (!this.sortDesc) {
        this.fetchAllCustomFields({
          table_id: this.id_menu,
          sort_by: this.sort_by
        })
      } else {
        this.fetchAllCustomFields({
          table_id: this.id_menu,
          sort_by_desc: this.sort_by_desc
        })
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    resetModal() {
      this.champsToAdd = {
        name: null,
        field_type: null,
        options: [],
        selectedOption: [],
        nameOption: null,
        order: null,
        sub_type: [],
        section: [],
        sub_categories: [],
        sub_categories_entet: [],
        typePixel: []
      }
      this.listePixelTypeUpdate = []
      this.view = false
      this.subcategorie = true
      this.id_menu = null
      this.champsToDelete = null
      this.champsToUpdate = null
      this.enabled = true
      this.dragging = false
      this.resetErrorCustomField()
    },
    Menu(linkOffre) {
      this.selectedlink = linkOffre
      this.sortDesc = false
      const element = this.getCustomFieldsMenu.find(
        e => e.name == this.selectedlink
      )
      if (element) {
        if (!this.sortDesc) {
          this.fetchAllCustomFields({
            table_id: element.id,
            sort_by: this.sort_by
          })
        } else {
          this.fetchAllCustomFields({
            table_id: element.id,
            sort_by_desc: this.sort_by_desc
          })
        }
      }
    },
    async Drapdownbutton(linkButton) {
      this.selectedlinkButton = linkButton
      if (linkButton == 'contacts') {
        this.selectedlinkButton = linkButton
      } else {
        this.selectedlinkButton = linkButton
      }
      await this.fetchListCategorieInTable({
        idTable: this.selectedlinkButton
      })
    },
    async changeCategorieInAddModal($event) {
      await this.fetchSubCategorieForCustemFields({
        event: $event
      })
    },
    handleDeleteClick(deletedType) {
      this.champsToDelete = { ...deletedType, menu: this.selectedlink }
      this.$refs['ModalDelete'].show()
    },
    async handleUpdateClick(updateChamp) {
      const element = this.getCustomFieldsMenu.find(
        e => e.name == this.selectedlink
      )
      updateChamp.sub_categories = []
      updateChamp.categorie = []
      const o = Object.assign({}, updateChamp)

      this.champsToUpdate = o
      this.champsToUpdate.field_type = this.champsToUpdate.fieldtype.id
      this.champsToUpdate.menu = this.selectedlink
      if (
        updateChamp.pixelTypes &&
        updateChamp.pixelTypes.data &&
        updateChamp.pixelTypes.data.length
      ) {
        for (
          let index = 0;
          index < updateChamp.pixelTypes.data.length;
          index++
        ) {
          if (
            this.champsToUpdate.pixelTypes &&
            this.champsToUpdate.pixelTypes.data &&
            this.champsToUpdate.pixelTypes.data.length
          ) {
            for (
              let index = 0;
              index < this.champsToUpdate.pixelTypes.data.length;
              index++
            ) {
              for (let j = 0; j < this.getListeTypePixel.length; j++) {
                if (
                  this.getListeTypePixel[j].id ==
                  this.champsToUpdate.pixelTypes.data[index].id
                )
                  this.listePixelTypeUpdate[
                    j
                  ] = this.champsToUpdate.pixelTypes.data[index].name_pixel
              }
            }
          } else {
            this.listePixelTypeUpdate = []
          }
        }
      }
      this.$refs['Modalupdatechamps'].show()
      await this.fetchListCategorieInTable({ idTable: element, include: null })
      await this.fetchOneCustomFields(this.champsToUpdate)
      await this.fetchSubCategorieForCustemFields({
        event: this.getCustemFieldsSubCategorie.categorie
      })
      let sub_categories = []
      for (
        let step = 0;
        step < this.getCustemFieldsSubCategorieList.length;
        step++
      ) {
        let find = this.getCustemFieldsSubCategorie.subcategories.data.find(
          item => item.id === this.getCustemFieldsSubCategorieList[step].id
        )
        if (find) {
          sub_categories.push({
            ...this.getCustemFieldsSubCategorieList[step],
            subcategory_id: this.getCustemFieldsSubCategorieList[step].id,
            is_subcategory_header: find.is_subcategory_header
          })
        } else {
          sub_categories.push({
            ...this.getCustemFieldsSubCategorieList[step],
            subcategory_id: null,
            is_subcategory_header: 0
          })
        }
      }
      this.champsToUpdate.categorie = this.getCustemFieldsSubCategorie.categorie
      this.champsToUpdate.sub_categories = [...sub_categories]
    },
    async changeCategorieInUpdateModal($event) {
      await this.fetchSubCategorieForCustemFields({
        event: $event
      })
      let sub_categories = []
      for (
        let step = 0;
        step < this.getCustemFieldsSubCategorieList.length;
        step++
      ) {
        let find = this.getCustemFieldsSubCategorie.subcategories.data.find(
          item => item.id === this.getCustemFieldsSubCategorieList[step].id
        )
        if (find) {
          sub_categories.push({
            ...this.getCustemFieldsSubCategorieList[step],
            subcategory_id: this.getCustemFieldsSubCategorieList[step].id,
            is_subcategory_header: find.is_subcategory_header
          })
        } else {
          sub_categories.push({
            ...this.getCustemFieldsSubCategorieList[step],
            subcategory_id: null,
            is_subcategory_header: 0
          })
        }
      }
      this.champsToUpdate.sub_categories = sub_categories
    },
    addOptionsAdd(click) {
      let count = this.champsToAdd ? this.champsToAdd.selectedOption.length : 0
      this.champsToAdd.selectedOption.push({
        name: click.nameOption,
        id: count++,
        order: count++
      })
      this.champsToAdd.nameOption = null
    },
    updateOptionsAdd(click) {
      const payload = {
        label: click.nameOption,
        field_id: click.id
      }
      this.addCustomFieldsOptions(payload)
      this.champsToUpdate.nameOption = null
    },
    updateOptionsName(options) {
      const payload = {
        label: options.label,
        order: options.order,
        id: options.id
      }
      this.updateCustomFieldsOptions(payload)
    },
    updateOptionsDelete(deleteOption) {
      this.deleteCustomFieldsOptions(deleteOption)
    },
    addOptionsDelete(value) {
      let key = false
      for (
        let index = 0;
        index < this.champsToAdd.selectedOption.length;
        index++
      ) {
        const option = this.champsToAdd.selectedOption[index]
        if (option.id == value) {
          key = index
          break
        }
      }
      if (key !== false) {
        this.champsToAdd.selectedOption.splice(key, 1)
      }
    },
    async handleSubmitAddCustomFields() {
      const payload = {
        name: this.champsToAdd.name,
        field_type: this.champsToAdd.field_type,
        id: this.selectedlinkButton.id,
        category: this.champsToAdd.section,
        subcategory: []
      }
      if (this.selectedlinkButton.name === 'projets') {
        payload.sub_types = this.champsToAdd.sub_type.map(item => {
          return item.id
        })
      }

      for (let i = 0; i < this.champsToAdd.sub_categories.length; i++) {
        if (
          this.champsToAdd.sub_categories[i] !== null &&
          this.champsToAdd.sub_categories[i] !== undefined
        ) {
          payload.subcategory.push({
            subcategory_id: this.champsToAdd.sub_categories[i],
            is_subcategory_header: this.champsToAdd.sub_categories_entet[i]
              ? 1
              : 0
          })
        }
      }

      const tabOptions = []
      for (
        let index = 0;
        index < this.champsToAdd.selectedOption.length;
        index++
      ) {
        if (this.champsToAdd.selectedOption[index]) {
          tabOptions.push(this.champsToAdd.selectedOption[index].name)
        }
      }
      payload.options = tabOptions
      payload.namePixel = this.champsToAdd.typePixel
      payload.idPixel = this.getListeTypePixel.filter(item => {
        return item.id
      })
      const response = await this.addCustomFields(payload)
      if (response) {
        this.hideModal('ModaladdChamps')
        this.getCustomFieldsMenu.forEach(c => {
          if (c.name == this.selectedlink) {
            this.id_menu = c.id
            if (!this.sortDesc) {
              this.fetchAllCustomFields({
                table_id: this.id_menu,
                sort_by: this.sort_by
              })
            } else {
              this.fetchAllCustomFields({
                table_id: this.id_menu,
                sort_by_desc: this.sort_by_desc
              })
            }
          }
        })
      }
    },
    async handleSubmitUpdateCustomFields() {
      let labelField = null
      this.getcustomFields.map(f => {
        if (f.id == this.champsToUpdate.id) {
          labelField = f.name
        }
      })
      let tabOptions = []

      for (
        let index = 0;
        index < this.champsToUpdate.sub_categories.length;
        index++
      ) {
        if (this.champsToUpdate.sub_categories[index].subcategory_id) {
          tabOptions.push({
            subcategory_id: this.champsToUpdate.sub_categories[index]
              .subcategory_id,
            is_subcategory_header: this.champsToUpdate.sub_categories[index]
              .is_subcategory_header
          })
        }
      }
      const payload = {
        name: this.champsToUpdate.name,
        field_type: this.field_type,
        id: this.champsToUpdate.id,
        label: labelField,
        sub_categories: tabOptions
      }
      if (this.champsToUpdate.subtypes && this.champsToUpdate.subtypes.data) {
        payload.sub_types = this.champsToUpdate.subtypes.data.map(item => {
          return item.id
        })
      }
      payload.namePixel = this.listePixelTypeUpdate
      payload.idPixel = this.getListeTypePixel.filter(item => {
        return item.id
      })
      for (
        let index = 0;
        index < this.champsToUpdate.pixelTypes.data.length;
        index++
      ) {
        this.champsToUpdate.pixelTypes.data[index].name_pixel = this
          .listePixelTypeUpdate[index]
          ? this.listePixelTypeUpdate[index]
          : ''
      }
      payload.namePixel = this.champsToUpdate.pixelTypes.data
      payload.idPixel = this.getListeTypePixel.filter(item => {
        return item.id
      })
      const response = await this.updateCustomFields(payload)
      if (response) {
        this.hideModal('Modalupdatechamps')
      }
    },
    async handleSubmitDeleteCustomFields() {
      const response = await this.deleteCustomFields(this.champsToDelete.id)
      if (response) {
        this.hideModal('ModalDelete')
      }
    },

    onCardDropAddCustomFieldOption(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      this.champsToAdd.selectedOption = this.applyDrag(
        this.champsToAdd.selectedOption,
        dropResult
      )
    },
    onCardDropUpdateCustomFieldOptions(dropResult, option, id_field) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      const result = this.applyDrag(option, dropResult)
      this.updateCustomFieldsOptions({
        label: option[dropResult.removedIndex].label,
        order: dropResult.addedIndex,
        id: option[dropResult.removedIndex].id
      })

      this.applyDragCustomFieldsOptions({
        result,
        id_field
      })
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    copyToClipboard(text) {
      const el = document.createElement('textarea')
      el.value = text.key
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$bvToast.toast(text.key, {
        title: ' Clé API copié',
        autoHideDelay: 2000,
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        solid: true
      })
    },
    removeDiacritics(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    normalizedContains(haystack, needle) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi')
      return regExp.test(this.removeDiacritics(haystack))
    }
  },
  computed: {
    ...mapGetters([
      'getcustomFields',
      'getAllTypescustomFields',
      'getCustomFieldsMenu',
      'getCustomFieldsLoading',
      'getCustomFieldsError',
      'getListCategorieInTable',
      'getCustemFieldsSubCategorie',
      'getCustemFieldsSubCategorieList',
      'getAllSubTypes',
      'getListeTypePixel'
    ]),
    computedCustomFieldsdefaultZero: function() {
      return this.getcustomFields.filter(z => z.default == 0)
    },
    computedCustomFieldsdefaultone: function() {
      return this.getcustomFields.filter(z => z.default == 1)
    },
    computedOptions: function() {
      return this.getcustomFields.filter(o => {
        if (o.id == this.champsToUpdate.id) {
          return o.options.data
        }
      })
    },
    sections: function() {
      if (!this.selectedlinkButton || !this.selectedlinkButton.name) {
        return []
      }

      return this.sectionsList.filter(s => {
        return s.section === this.selectedlinkButton.name
      })
    },
    sectionsUpdate: function() {
      if (!this.getListCategorieInTable) {
        return []
      }
      return this.getListCategorieInTable
    },
    getcustomFieldsComputed: function() {
      const search = this.searchFilter.trim()
      if (!search) {
        return this.getcustomFields
      }
      return this.getcustomFields.filter(c =>
        this.normalizedContains(c.name.toLowerCase(), search.toLowerCase())
      )
    },
    computedFields() {
      if (this.selectedlink === 'contacts') {
        return this.fields
      } else {
        return this.fieldsProject
      }
    },
    computedsubTypeExiste() {
      if (this.computedFields.find(item => item.key === 'subtype')) {
        return true
      } else {
        return false
      }
    }
  },
  async mounted() {
    await this.fetchAllTablesCustomFields(this.customizable)
    this.fetchAllCustomFieldsTypes()
    this.selectedlink = 'contacts'
    this.getCustomFieldsMenu.forEach(c => {
      if (c.name == this.selectedlink) {
        this.id_menu = c.id
        if (!this.sortDesc) {
          this.fetchAllCustomFields({
            table_id: this.id_menu,
            sort_by: this.sort_by
          })
        } else {
          this.fetchAllCustomFields({
            table_id: this.id_menu,
            sort_by_desc: this.sort_by_desc
          })
        }
      }
    })
    this.fetchAllSubTypesProject()
    this.fetchListTypePixel()
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.personnalises-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .clear {
    clear: both;
  }
  .slide-toggle {
    display: none;
  }
  .slidemenu {
    min-width: 163px;
    overflow: hidden;
  }
  .menuBlock {
    display: flex;
    margin-bottom: 1.5em;
  }
  .slidemenu label {
    width: 45%;
    text-align: center;
    display: block;
    float: left;
    color: #333;
  }
  label {
    display: inline-block;
    margin-bottom: 0;
  }
  .slidemenu label:hover {
    cursor: pointer;
    color: #666;
  }
  .slidemenu label span {
    display: block;
    padding: 0px;
    font-weight: bold;
    font-size: 13px;
  }
  .slidemenu label .icon {
    font-size: 20px;
    border: solid 2px #333;
    text-align: center;
    height: 50px;
    width: 50px;
    display: block;
    margin: 0 auto;
    line-height: 50px;
    border-radius: 50%;
  }
  .slider {
    width: 91%;
    height: 2px;
    display: block;
    background: #ccc;
    margin-top: 3px;
    border-radius: 5px;
  }
  .slider .bar {
    width: 53%;
    height: 5px;
    background: #333;
    border-radius: 5px;
  }
  /*Animations*/
  .slidemenu label,
  .slider .bar {
    transition: all 500ms ease-in-out;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
  }
  /*Toggle*/
  .slidemenu .slide-toggle:checked + label {
    opacity: 1;
  }
  .slidemenu #slide-item-1:checked ~ .slider .bar {
    margin-left: 0;
  }
  .slidemenu #slide-item-2:checked ~ .slider .bar {
    margin-left: 48%;
  }
  .content-table-custem-fields {
    .search {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    table {
      width: 100%;
      //text-align: center;

      color: #212529;
      border: 0;
      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #b9babb;

      font-size: 12px;

      tbody {
        .content-key {
          display: flex;
          justify-content: space-around;
        }
        .copier {
          cursor: pointer;
        }
        .font-div,
        .font-p {
          font-size: 15px;
          margin-top: 0;
          margin-bottom: 0;
          &.font-div {
            text-transform: capitalize;
          }
        }
        .chacked {
          color: #08a742;
        }
        .ellipsis {
          white-space: nowrap;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .actions {
          text-align: left;
          margin-left: 7px;
          .font {
            font-weight: normal;
            text-transform: capitalize;
            font-size: 14px;
            color: #007bff;
          }
        }
        .icon-exclamation {
          text-decoration: line-through;
          text-decoration-color: #e73e01;
        }
        .name {
          text-transform: capitalize;
        }
        .equipe {
          font-weight: normal;
          text-transform: capitalize;
          font-size: 12px;
          color: #317ae2;
        }
        .type {
          font-weight: normal;
          text-transform: capitalize;
          font-size: 12px;
          color: #317ae2;
        }
      }
    }
    .input-search {
      border: 1px solid #dadada;
      outline: none;
      font-size: 16px;
      height: 40px;
      background: #fff;
      padding-left: 10px;
    }

    .searchIcon {
      padding: 9px;
      border: 1px solid #d6d8db;
      font-size: 16px;
      background: #d6d8da17;
    }
  }

  .b-table-sticky-header {
    overflow-y: initial;
  }
  .color {
    color: #6c757d;
  }
  .title-2-tab {
    color: #212529;
    background-color: #e9ecef;
  }
  .contenu {
    font-size: 12px;
  }
  @media screen and (max-width: 580px) {
    .header {
      display: block;
    }
    .content-table-custem-fields {
      .search {
        display: block;
        margin-bottom: 0px;
        input {
          width: 85%;
        }
      }
      .responsive-table {
        overflow: auto;
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border: 0;
  border-bottom: 1px solid #dee2e6;
  margin-top: -8px;
  font-size: 11px;
  // text-align: center;
}
.b-table-sticky-header {
  overflow-y: auto;
  padding-top: 19px;
  max-height: 500px;
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: -webkit-sticky;
  position: sticky;
  top: -15px;
  z-index: 2;
  padding: 5px;
  font-size: 10px;
}
.table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
  color: #212529;
  background-color: #ebebeb;
  //   font-size: smaller;
}
.b-table-sticky-header > .table,
.table-responsive > .table,
[class*='table-responsive-'] > .table {
  margin-bottom: 0;
  text-align: -webkit-right;
}
.btn-link:hover {
  color: #6c757de3;
  text-decoration: underline;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0rem 0rem;
  font-size: 1.25rem;
  line-height: 0.5;
  border-radius: 1.3rem;
}
.table th,
.table td {
  padding: 0px 0px;
  text-transform: capitalize;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}
.dropdown-menu.show {
  display: block;
  width: 100%;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 500px;
}
dropdown-item.active,
.dropdown-item:active {
  color: #212529;
  text-decoration: none;
  background-color: #6c757d9e;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #212529;
  text-decoration: none;
  background-color: #d6d8db;
}
.dropdown-item {
  display: block;
  text-transform: capitalize;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.input-group {
  padding-bottom: 2px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.copy {
  margin-left: 5px;
}
.loadingMenu {
  color: #2196f3;
  margin-left: 18%;
}
.loading {
  color: #2196f3;
}

.img_copy {
  margin-left: -18px;
}
.icon_copy {
  font-size: 16px;
}
</style>
<style lang="scss">
.content-table-custem-fields {
  .default-td-class {
    text-align: center;
  }
  .subtype-td-class {
    min-width: 100px;
  }
  .table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
  .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
    background-position: left calc(3.3rem / 2) center;
    padding-left: calc(1.3rem + -0.35em);
  }
  .table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
  }
  .table-sm th,
  .table-sm td {
    vertical-align: middle;
    border: 1px solid #dee2e6;
  }
}

.toast {
  font-size: 0.75rem;
}
.btn-link {
  font-weight: 400;
  color: #212529;
  text-decoration: none;
}
#Modalupdatechamps,
#ModaladdChamps {
  .aucun-sous-category,
  .bleu-aucun {
    text-align: center;
    color: #2dabe2;
    font-size: 14px;
  }
  .a-card {
    margin-top: 13px;
  }
  .a-card-header {
    cursor: pointer;
    .a-card-header-flex {
      display: flex;
      align-items: center;
      &.a-card-header-montant,
      &.a-card-header-type-project,
      &.a-card-header-subcategorie {
        justify-content: space-between;
      }
      .title-card {
        font-size: 16px;
      }
      .style-title-type {
        font-size: 12px;
        font-weight: bold;
      }
      .type-projet {
        font-weight: bold;
      }
      .body-card {
        .value-ttc {
          margin-left: 12px;
        }
      }
      .delete-project-card {
        margin-left: 207px;
        .delete-project {
          a {
            color: #ff5722;
            font-weight: bold;
          }
        }
      }
    }
    .line-hr {
      margin-top: 10px;
    }
  }
  .card-body {
    padding: 7px 11px 7px 11px;
    .show-button {
      background-color: #e5e5e580;
      border: 1px solid #6c757d52;
      margin-left: 270px;
      text-align: center;
      padding: 3px;
      border-radius: 10px;
      font-weight: bold;
      min-width: 50px;
      max-width: 100px;
    }
  }
  .display-body {
    display: none;
  }
  .flesh-down,
  .flesh-up {
    font-size: 15px;
  }
  .custom-control-label {
    position: relative;
    margin-bottom: -8px;
    vertical-align: top;
  }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    cursor: all-scroll;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

//lultiselect component style
.multiselectChamp {
  min-height: 29px;
  width: 100%;
}
</style>
